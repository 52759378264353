import store from "@/store";
import axios from "axios";

const jwt = store.state.auth.token_jwt;
export default axios.create({
  baseURL: `${process.env.VUE_APP_LC_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    token: `${jwt}`,
  },
});
