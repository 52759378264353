import LC from "@/repositories/lisacomunica/lc";

export default {
  // SMS
  index(queryString) {
    let resource = `/sms/all`;
    return queryString
      ? LC.get(`${resource}?${queryString}`)
      : LC.get(`${resource}`);
  },
  show(id) {
    let resource = `/sms`;
    return LC.get(`${resource}/${id}`);
  },
  store(payload) {
    let resource = `/sms/new`;
    // save the item
    return LC.post(`${resource}`, payload);
  },
  update(id, payload) {
    let resource = `/sms`;
    // update the item
    return LC.put(`${resource}/${id}`, payload);
  },
  destroy(id) {
    let resource = `/sms`;
    // delete the item
    return LC.delete(`${resource}/${id}`);
  },
  // SMS (presets)
  preset_index(queryString) {
    let resource = `/sms/preset/all`;
    return queryString
      ? LC.get(`${resource}?${queryString}`)
      : LC.get(`${resource}`);
  },
  preset_show(id) {
    let resource = `/sms/preset`;
    return LC.get(`${resource}/${id}`);
  },
  start(payload) {
    let resource = "/sms/start";
    // save the procedure_id
    return LC.post(`${resource}`, payload);
  },
};
