<template>
  <editor :api-key="apikey" :init="config" v-model="inputVal" />
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  data() {
    return {
      apikey: process.env.VUE_APP_TINYMCE_API_KEY,
      config: {
        toolbar:
          "fullscreen | undo redo | formatselect | bold italic underline forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | tags | image | pagebreak | table | code",
        plugins: [
          "lists link image charmap table",
          "visualblocks code fullscreen",
          "table paste pagebreak",
        ],
        image_list: [],
        table_row_class_list: [
          { title: "None", value: "" },
          { title: "Riga ripetuta", value: "repeatRow" },
        ],
        pagebreak_separator:
          '<div style="page-break-after: always;">&nbsp;</div>',
        setup: function (editor) {
          editor.ui.registry.addMenuButton("tags", {
            text: "Tags",
            fetch: (callback) => {
              const items = [
                {
                  type: "menuitem",
                  text: "Data Odierna Estesa",
                  onAction: () => editor.insertContent("[[DATA_ODIERNA_LONG]]"),
                },
                {
                  type: "menuitem",
                  text: "Data Odierna ",
                  onAction: () =>
                    editor.insertContent("[[DATA_ODIERNA_SHORT]]"),
                },
                {
                  type: "nestedmenuitem",
                  text: "Destinatario",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Denominazione",
                        onAction: () =>
                          editor.insertContent(
                            "[[NOME]] [[COGNOME]][[RAGIONE_SOCIALE]]"
                          ),
                      },
                      {
                        type: "menuitem",
                        text: "Nome",
                        onAction: () => editor.insertContent("[[NOME]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Cognome",
                        onAction: () => editor.insertContent("[[COGNOME]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Ragione Sociale",
                        onAction: () =>
                          editor.insertContent("[[RAGIONE_SOCIALE]]"),
                      },
                      {
                        type: "menuitem",
                        text: "PIVA",
                        onAction: () => editor.insertContent("[[PIVA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Codice Fiscale",
                        onAction: () => editor.insertContent("[[CF]]"),
                      },
                      {
                        type: "nestedmenuitem",
                        text: "Contatti",
                        getSubmenuItems: function () {
                          return [
                            {
                              type: "menuitem",
                              text: "Telefono",
                              onAction: () =>
                                editor.insertContent("[[TEL_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Cellulare",
                              onAction: () =>
                                editor.insertContent("[[CELL_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Email",
                              onAction: () =>
                                editor.insertContent("[[EMAIL_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Pec",
                              onAction: () =>
                                editor.insertContent("[[PEC_MAIN]]"),
                            },
                          ];
                        },
                      },
                      {
                        type: "nestedmenuitem",
                        text: "Indirizzo Principale",
                        getSubmenuItems: function () {
                          return [
                            {
                              type: "menuitem",
                              text: "Indirizzo",
                              onAction: () =>
                                editor.insertContent("[[INDIRIZZO_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Cap",
                              onAction: () =>
                                editor.insertContent("[[CAP_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Comune",
                              onAction: () =>
                                editor.insertContent("[[COMUNE_MAIN]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Provincia",
                              onAction: () =>
                                editor.insertContent("[[PROVINCIA_MAIN]]"),
                            },
                          ];
                        },
                      },
                      {
                        type: "nestedmenuitem",
                        text: "Indirizzo Corrispondenza",
                        getSubmenuItems: function () {
                          return [
                            {
                              type: "menuitem",
                              text: "Indirizzo",
                              onAction: () =>
                                editor.insertContent("[[INDIRIZZO_CORR]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Cap",
                              onAction: () =>
                                editor.insertContent("[[CAP_CORR]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Comune",
                              onAction: () =>
                                editor.insertContent("[[COMUNE_CORR]]"),
                            },
                            {
                              type: "menuitem",
                              text: "Provincia",
                              onAction: () =>
                                editor.insertContent("[[PROVINCIA_CORR]]"),
                            },
                          ];
                        },
                      },
                    ];
                  },
                },
                {
                  type: "nestedmenuitem",
                  text: "Righe",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Data",
                        onAction: () => editor.insertContent("[[DATA_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Nominativo Contraente",
                        onAction: () =>
                          editor.insertContent(
                            "[[NOMINATIVO_CONTRAENTE_RIGA]]"
                          ),
                      },
                      {
                        type: "menuitem",
                        text: "Compagnia",
                        onAction: () =>
                          editor.insertContent("[[COMPAGNIA_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Numero Polizza",
                        onAction: () =>
                          editor.insertContent("[[NUMERO_POLIZZA_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Ramo",
                        onAction: () => editor.insertContent("[[RAMO_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Netto",
                        onAction: () => editor.insertContent("[[NETTO_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Tasse",
                        onAction: () => editor.insertContent("[[TASSE_RIGA]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Lordo",
                        onAction: () => editor.insertContent("[[LORDO_RIGA]]"),
                      },
                    ];
                  },
                },
                {
                  type: "nestedmenuitem",
                  text: "Totale Righe",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Netto",
                        onAction: () => editor.insertContent("[[TOT_NETTO]]"),
                      },
                      {
                        type: "menuitem",
                        text: "Lordo",
                        onAction: () => editor.insertContent("[[TOT_LORDO]]"),
                      },
                    ];
                  },
                },
                {
                  type: "menuitem",
                  text: "Note",
                  onAction: () => editor.insertContent("[[NOTE]]"),
                },
              ];
              callback(items);
            },
          });
        },
        menubar: false,
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px} .repeatRow {background-color: #dedede}",
        branding: false,
      },
    };
  },
  props: {
    value: null,
    height: {
      type: Number,
      default: 100,
    },
    image_list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    editor: Editor,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.$set(this.config, "height", this.height);
    this.$set(this.config, "image_list", this.image_list);
  },
};
</script>

<style scoped>
.base-label:focus {
  outline: none !important;
}
</style>
