import LC from "@/repositories/lisacomunica/lc";
const options = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export default {
  // Image
  index(queryString) {
    let resource = `/image/all`;
    return queryString
      ? LC.get(`${resource}?${queryString}`)
      : LC.get(`${resource}`);
  },
  show(id) {
    let resource = `/image`;
    return LC.get(`${resource}/${id}`);
  },
  stream(id) {
    let resource = `/image`;
    return LC.get(`${resource}/stream/${id}`, { responseType: "blob" });
  },
  store(payload) {
    let resource = `/image/new`;
    // save the item
    return LC.post(`${resource}`, payload, options);
  },
  update(id, payload) {
    let resource = `/image`;
    // update the item
    return LC.post(`${resource}/${id}`, payload);
  },
  destroy(id) {
    let resource = `/image`;
    // delete the item
    return LC.delete(`${resource}/${id}`);
  },
};
