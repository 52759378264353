import LC from "@/repositories/lisacomunica/lc";

export default {
  // Email
  index(queryString) {
    let resource = `/logs/all`;
    return queryString
      ? LC.get(`${resource}?${queryString}`)
      : LC.get(`${resource}`);
  },
  show(id) {
    let resource = `/logs`;
    return LC.get(`${resource}/${id}`);
  },
};
