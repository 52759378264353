import LC from "@/repositories/lisacomunica/lc";

export default {
  // PDF
  index(queryString) {
    let resource = `/pdf/all`;
    return queryString
      ? LC.get(`${resource}?${queryString}`)
      : LC.get(`${resource}`);
  },
  show(id) {
    let resource = `/pdf`;
    return LC.get(`${resource}/${id}`);
  },
  store(payload) {
    let resource = `/pdf/new`;
    // save the item
    return LC.post(`${resource}`, payload);
  },
  preview(payload) {
    let resource = `/pdf/resolve/preview`;
    // save the item
    return LC.post(`${resource}`, payload, { responseType: "blob" });
  },
  update(id, payload) {
    let resource = `/pdf`;
    // update the item
    return LC.put(`${resource}/${id}`, payload);
  },
  destroy(id) {
    let resource = `/pdf`;
    // delete the item
    return LC.delete(`${resource}/${id}`);
  },
  // PDF (presets)
  preset_index(queryString) {
    let resource = `/pdf/preset/all`;
    return queryString
      ? LC.get(`${resource}?${queryString}`)
      : LC.get(`${resource}`);
  },
  preset_show(id) {
    let resource = `/pdf/preset`;
    return LC.get(`${resource}/${id}`);
  },
};
